<!-- 水位监测 -->
<template>
  <div class="main_box">
    <div class="query_condition_charts" style="">
      <div
        :class="{ active: chartTabs == index }"
        class="chartItem"
        @click="(chartTabs = index), ClickTabs()"
        v-for="(item, index) in chartShowName"
        :key="index"
      >
        {{ item }}
      </div>
      <div class="query_condition">
        <span style="color: #606266;padding-left: 15px"> 站点名称：</span>
        <el-cascader
          style="width: 260px; line-height: 0;"
          v-model="siteName1"
          placeholder="请选择站点"
          :options="waterList"
          :props="{
            multiple: true,
            checkStrictly: true,
            value: 'deviceAddress',
            label: 'siteName',
          }"
          collapse-tags
          @change="siteSelect1"
        ></el-cascader>
        <span v-show="chartTabs==0" style="color: #606266;padding-left: 15px"> 站点名称：</span>
        <el-cascader
          style="width: 260px; line-height: 0;"
          v-model="siteName2"
          placeholder="请选择站点"
          :options="waterList"
          :props="{
            multiple: true,
            checkStrictly: true,
            value: 'deviceAddress',
            label: 'siteName',
          }"
          collapse-tags
          v-show="chartTabs==0"
          @change="siteSelect2"
        ></el-cascader>
        <!-- <el-select
          v-model="siteName1"
          placeholder="请选择站点"
          size="mini"
          @change="siteSelect1"
        >
          <el-option
            v-for="(item, index) in waterList"
            :key="item.deviceAddress"
            :label="index + 1 + ' ' + item.siteName"
            :value="item.siteName"
          >
          </el-option>
        </el-select> -->
        <!-- <el-select
          v-model="siteName2"
          placeholder="请选择站点"
          size="mini"
          @change="siteSelect2"
        >
          <el-option
            v-for="(item, index) in waterList"
            :key="item.deviceAddress"
            :label="index + 1 + ' ' + item.siteName"
            :value="item.siteName"
          >
          </el-option>
        </el-select> -->
        <span style="color: #606266;padding-left: 15px"> 时间：</span>
        <el-date-picker
          :editable="false"
          v-model="waterChartValue.startTime"
          type="month"
          placeholder="选择月份"
          class="check_date"
          value-format="yyyy-MM"
          :picker-options="pickerOptions"
          @change="tiemSelect"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="chartContent">
      <div id="main" style="width: 100%; height: 200px"></div>
    </div>
    <div>
      <el-table
        height="500px"
        :row-style="{ height: '45px !important' }"
        :cell-style="{ padding: '0px' }"
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF',
        }"
        :data="tableView"
        stripe
        style="width: 100%; margin-bottom: 20px"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column
          type="index"
          prop="sid"
          label="序号"
          width="95"
        ></el-table-column>
        <el-table-column prop="siteName" label="站点名称"></el-table-column>
        <el-table-column prop="gateLevel" label="闸前水位(cm)"></el-table-column>
        <el-table-column prop="currentLevel" label="闸后水位(cm)">
        </el-table-column>
        <el-table-column prop="velocity" label="流速(m/s)"></el-table-column>
        <el-table-column prop="traffic" label="瞬时流量(m³/h)"></el-table-column>
        <el-table-column prop="trafficSum" label="累计流量(m³)"></el-table-column>
        <el-table-column prop="dataTime" label="采集时间"></el-table-column>
        <el-table-column width="200" label="统计">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleEdit(scope.row, '1')"
            >月度
            </el-button
            >
            <el-button class="edit" @click="handleEdit(scope.row, '2')"
            >年度
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        hide-on-single-page:true
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.pageNum"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      width="70%"
      @close="closeDialog"
    >
      <div>
        <span class="table_title">{{ singleSiteName }}</span>
        <el-date-picker
          :editable="false"
          v-model="searchValue.queryTime"
          type="month"
          placeholder="选择月份"
          class="check_date"
          value-format="yyyy-MM"
          :picker-options="pickerOptions"
          v-show="numBtn == 1"
        >
        </el-date-picker>
        <el-date-picker
          :editable="false"
          v-model="searchValue.queryTime"
          type="year"
          placeholder="选择年份"
          class="check_date"
          value-format="yyyy"
          :picker-options="pickerOptions"
          v-show="numBtn == 2"
        >
        </el-date-picker>
        <el-button type="primary" @click="handleSearch" ref="HSearch"
        >查询
        </el-button
        >
        <el-button type="info" @click="handleReset">重置</el-button>
        <div
          id="tableMain"
          ref="tableChart"
          style="width: 1300px; height: 300px"
        ></div>
      </div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF',
        }"
        style="height: 50%"
        :data="gridData"
      >
        <el-table-column
          property="typeName"
          label=""
          width="200"
        ></el-table-column>
        <el-table-column
          :property="'index' + index"
          :label="tableH[index]"
          width="100"
          v-for="(item, index) in tableLength"
          :key="index"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {FlowMeterData, YearMonth} from "../../../api/index";
import {getNowDayFn, getNowTime, getWeekDate} from "../../../utils/methods";
import Echart from "../../../components/Echart/Echart";
import {monthList, yearList} from "../../RainCondition/index";
import echarts from "echarts";

export default {
  components: {Echart},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      dialogTableVisible: false,
      tableData: [],
      tableView: [],
      logTab: [],
      ChartData1: null,
      addShow: false,
      time: "",
      waterList: [], //拦河闸下拉
      pagination: {
        size: 10,
        pageNum: 1,
        total: 0,
      },
      cols: monthList,
      editVal: {
        num: "",
      },
      listMonth: [],
      listDay: [],
      searchValue: {
        devName: "",
        queryTime: "",
      },
      singleSiteName: "",
      singleTableData: [],
      gridData: [
        {typeName: "流速平均值(m/s)"},
        {typeName: "水位平均值(cm)"},
        {typeName: "瞬时流量平均值(m³/h)"},
        {typeName: "日流量(m³)"},
      ],
      tableLength: 12,
      numBtn: 1,
      siteName1: "",
      siteName2: "",
      waterChartValue: {
        startTime: "",
        // siteAddress: "",
        // siteAddressTwo: "",
        AddressList: [],
        AddressTwoList: [],
      },
      selectSiteInfor: null,
      loading: false,
      chartShowName: ["水位", "流量"],
      chartTabs: 0,
      charts: "",
      columnarCharts: "",
      opinionData: [],
      tableH: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // tabs切换
    ClickTabs() {
      this.waterChartValue.AddressList = []
      this.waterChartValue.AddressTwoList = []
      // this.siteName1=this.waterList[0].deviceAddress
      // this.siteName2=this.waterList[0].deviceAddress
      // if (this.chartTabs == 0) {
      //   this.getSiteWaterLevel();
      // } else {
      //   this.getSiteFlow();
      // }
      this.getList()
    },
    // 第一个下拉框
    siteSelect1(value, label) {
      // let obj = {};
      // obj = this.waterList.find(function (item) {
      //   return item.siteName === label;
      // });
      // this.waterChartValue.siteAddress = obj.deviceAddress;
      this.waterChartValue.AddressList = value;
      if (value.length != 0) {
        if (this.chartTabs == 0) {
          this.getSiteWaterLevel();
        } else {
          this.getSiteFlow();
        }
      } else {
        this.$message('请至少选中一个站点');
      }
    },
    // 第二个下拉框
    siteSelect2(value, label) {
      // let obj = {};
      // obj = this.waterList.find(function (item) {
      //   return item.siteName === value;
      // });
      // this.waterChartValue.siteAddressTwo = obj.deviceAddress;
      this.waterChartValue.AddressTwoList = value;
      if (value.length != 0) {
        if (this.chartTabs == 0) {
          this.getSiteWaterLevel();
        } else {
          this.getSiteFlow();
        }
      } else {
        this.$message('请至少选中一个站点');
      }
    },
    // 表格操作按钮
    tiemSelect(val) {
      if (this.chartTabs == 0) {
        this.getSiteWaterLevel();
      } else {
        this.getSiteFlow();
      }
    },
    // 获取水位
    getSiteWaterLevel() {
      let {AddressList, AddressTwoList, startTime} = this.waterChartValue;
      this.$get("/site/findWaterBySite", {
        siteAddressList: AddressList.toString(),
        siteAddressTwoList: AddressTwoList.toString(),
        startTime: startTime,
      }).then((res) => {
        this.charts.clear();
        this.drawLine();
        let chartsData = {
          tooltip: {
            trigger: "axis",
            formatter(params) {
              var relVal = params[0].name
              for (var i = 0, l = params.length; i < l; i++) {
                if (params[i].seriesName.indexOf('次数') == -1) {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value).toFixed(2) + '(cm)'
                } else {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value)
                }
              }
              return relVal
            },
          },
          // charts图例
          legend: {
            data: [],
          },
          // x轴
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: res.data.dates,
          },
          series: [],
        };
        chartsData.series = [];
        // 处理数据
        res.data.series.forEach((item, i) => {
          let noNull = []
          item.data.forEach((w) => {
            if (w == null) {
            } else {
              noNull.push(w)
            }
          })
          chartsData.legend.data.push(item.name);
          chartsData.series.push({
            name: item.name,
            type: "line",
            // stack: "Total",
            smooth: true,
            data: noNull,
          });
        });
        this.charts.setOption(chartsData);
      });
    },
    // 获取流量
    getSiteFlow() {
      let {AddressList, AddressTwoList, startTime} = this.waterChartValue;
      this.$get("/site/findTrafficBySite", {
        siteAddressList: AddressList.toString(),
        siteAddressTwoList: AddressTwoList.toString(),
        startTime: startTime,
      }).then((res) => {
        // 清除图表内容
        this.charts.clear();
        this.drawLine();
        let chartsData = {
          tooltip: {
            trigger: "axis",
            formatter(params) {
              var relVal = params[0].name
              for (var i = 0, l = params.length; i < l; i++) {
                if (params[i].seriesName.indexOf('次数') == -1) {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value).toFixed(2) + '(m³)'
                } else {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value)
                }
              }
              return relVal
            },
          },
          // charts图例
          legend: {
            data: [],
          },
          // x轴
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: res.data.dates,
          },
          series: [],
        };
        res.data.series.forEach((item) => {
          console.log(item.data)
          let noNullflow = []
          item.data.forEach((w) => {
            if (w == null) {
            } else {
              noNullflow.push(w)
            }
          })

          chartsData.legend.data.push(item.name);
          chartsData.series.push({
            name: item.name,
            type: "line",
            // stack: "Total",
            smooth: true,
            data: noNullflow,
          });
        });
        this.charts.setOption(chartsData);
      });
    },
    // 弹窗内清空时间
    closeDialog() {
      this.searchValue.queryTime = "";
    },
    // 弹窗内重置
    handleEdit(val, num) {
      this.numBtn = num;
      this.selectSiteInfor = val;
      this.singleSiteName = val.siteName;
      this.dialogTableVisible = true;
      if (num == 1) {
        // this.searchValue.queryTime = getNowDayFn().slice(0, 7);
        this.getMonthLevel(val.deviceAddress);
      } else {
        // this.searchValue.queryTime = getNowDayFn().slice(0, 4);
        this.getYearLevel(val.deviceAddress);
      }
    },
    // 获取月度数据
    getMonthLevel(deviceAddress) {
      this.gridData = [
        {typeName: "流速平均值(m/s)"},
        {typeName: "水位平均值(cm)"},
        {typeName: "瞬时流量平均值(m³/h)"},
        {typeName: "日流量(m³)"},
      ];
      this.$get("/site/getSiteDataCountByMonth", {
        deviceAddress: deviceAddress,
        yearMonth: this.searchValue.queryTime,
      }).then((res) => {
        // console.log("res==月==", res);
        this.singleTableData = res.data;
        this.tableLength = res.data.length;
        this.columnarCharts = echarts.init(this.$refs.tableChart);
        let columnarData = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter(params) {
              var relVal = params[0].name
              for (var i = 0, l = params.length; i < l; i++) {
                if (params[i].seriesName.indexOf('次数') == -1) {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value).toFixed(2) + '(cm)'
                } else {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value)
                }
              }
              return relVal
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: [],
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "水位平均值",
              type: "bar",
              barWidth: "60%",
              data: [],
            },
          ],
        };
        this.tableH = []
        res.data.forEach((item, index) => {
          this.$set(this.gridData[0], "index" + index, item.velocity);
          this.$set(this.gridData[1], "index" + index, item.level);
          this.$set(this.gridData[2], "index" + index, item.trafficNow);
          this.$set(this.gridData[3], "index" + index, item.traffic);
          columnarData.xAxis[0].data.push(item.date.slice(-2) + "日");
          this.tableH.push(index + 1 + "日");
          columnarData.series[0].data.push(item.level);
        });
        this.columnarCharts.setOption(columnarData);
      });
    },
    // 获取年度数据
    getYearLevel(deviceAddress) {
      this.gridData = [
        {typeName: "流速平均值(m/s)"},
        {typeName: "水位平均值(cm)"},
        {typeName: "瞬时流量平均值(m³/h)"},
        {typeName: "月流量(m³)"},
      ];
      this.$get("/site/getSiteDataCountByYear", {
        deviceAddress: deviceAddress,
        year: this.searchValue.queryTime,
      }).then((res) => {
        this.singleTableData = res.data;
        this.tableLength = 12;
        this.columnarCharts = echarts.init(this.$refs.tableChart);
        let columnarData = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter(params) {
              var relVal = params[0].name
              for (var i = 0, l = params.length; i < l; i++) {
                if (params[i].seriesName.indexOf('次数') == -1) {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value).toFixed(2) + '(cm)'
                } else {
                  relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Number(params[i].value)
                }
              }
              return relVal
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: [
                "1月",
                "2月",
                "3月",
                "4月",
                "5月",
                "6月",
                "7月",
                "8月",
                "9月",
                "10月",
                "11月",
                "12月",
              ],
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "水位平均值",
              type: "bar",
              barWidth: "60%",
              data: [],
            },
          ],
        };
        this.tableH = []
        res.data.forEach((item, index) => {
          this.$set(this.gridData[0], "index" + index, item.velocity);
          this.$set(this.gridData[1], "index" + index, item.level);
          this.$set(this.gridData[2], "index" + index, item.trafficNow);
          this.$set(this.gridData[3], "index" + index, item.traffic);
          columnarData.series[0].data.push(item.level);
          this.tableH.push(index + 1 + "月");
        });
        this.columnarCharts.setOption(columnarData);
      });
    },
    // 图表
    drawLine() {
      this.charts = echarts.init(document.getElementById("main"));
      this.charts.setOption({
        // charts图例
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        // x轴
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        // y轴
        yAxis: {
          type: "value",
        },
        // 数据
        series: [],
      });
    },
    // 弹窗内图表
    columnar() {
      // this.columnarCharts = echarts.init(document.getElementById("tableMain"));
      this.columnarCharts = echarts.init(this.$refs.tableChart);
      this.columnarCharts.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "60%",
            data: [],
          },
        ],
      });
    },
    // 列表
    getList() {
      this.waterChartValue.AddressList = [];
      this.waterChartValue.AddressList = [];
      this.loading = true;
      let {pageNum, size} = this.pagination;
      this.$get("/site/findSiteList", {}).then((res) => {
        this.pagination.total = res.data.length;
        this.tableData = res.data;
        this.tableView = [];
        for (var i = 0; i < size; i++) {
          this.tableView.push(this.tableData[i]);
        }
        this.loading = false;
      });
      this.$get("/site/findSiteList", {}).then((res) => {
        this.waterList = res.data;
        this.siteName1 = res.data[0].deviceAddress;
        this.siteName2 = res.data[0].deviceAddress;
        this.waterChartValue.AddressList.push(res.data[0].deviceAddress);
        this.waterChartValue.AddressTwoList.push(res.data[0].deviceAddress);
        if (this.chartTabs == 0) {
          this.getSiteWaterLevel();
        } else {
          this.getSiteFlow();
        }
      });
    },
    // 分页
    handleCurrent(page) {
      this.loading = true;
      this.pagination.pageNum = page;
      this.pagination.size = 10;
      let {pageNum, size} = this.pagination;
      let newArr = [];
      let index = 0;
      for (let i = 0; i < this.pagination.total; i++) {
        if (i % size === 0 && i !== 0) {
          newArr.push(this.tableData.slice(index, i));
          index = i;
        }
        if (i + 1 === this.pagination.total) {
          newArr.push(this.tableData.slice(index, i + 1));
        }
      }
      this.tableView = newArr[page - 1];
      this.loading = false;
    },
    // 查询
    handleSearch() {
      this.handleEdit(this.selectSiteInfor, this.numBtn);
    },
    // 重置
    handleReset() {
      this.pagination.pageNum = 1;
      this.searchValue.devName = "";
      this.searchValue.queryTime = "";
      this.handleSearch()
    },
    handleExport() {
      let {endTime, startTime, siteName, searhTime} = this.searchValue;
      if (searhTime.length) {
        startTime = searhTime[0];
        endTime = searhTime[1];
        fetch(
          this.$axios.defaults.baseURL +
          `gateUpRecordItem/traffic/export?endTime=${endTime}&startTime=${startTime}&siteName=${siteName}`,
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
          .then((res) => {
            return res.blob();
          })
          .catch((err) => {
            // console.log(err);
          })
          .then((e) => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "流量统计查询.xlsx";
            a.click();
          });
      } else {
        fetch(
          this.$axios.defaults.baseURL +
          `gateUpRecordItem/traffic/export?endTime=${endTime}&startTime=${startTime}&siteName=${siteName}`,
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
          .then((res) => {
            return res.blob();
          })
          .catch((err) => {
            // console.log(err);
          })
          .then((e) => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "流量统计查询.xlsx";
            a.click();
          });
      }
    },
  },
  created() {
    this.waterChartValue.startTime = getNowDayFn().slice(0, 7);
    this.getList();
  },
  mounted() {
    this.$nextTick(function () {
      this.drawLine();
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-range-input {
  background: #f3f4f8 !important;
}

::v-deep .el-range-separator {
  background: #f3f4f8 !important;
}

::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: calc(265 / 1920 * 100vw) !important;
}

::v-deep .el-dialog .el-dialog__body .el-input__inner {
  border: 1px solid #c0c4cc;
}

::v-deep .el-dialog {
  padding: 0 15px;
}

::v-deep .el-button--primary {
  margin: 0 10px;
}

.columnarChart {
  width: 100%;
  height: 230px;
  margin: 0px auto;
}

::v-deep .el-input__prefix {
  display: none;
}

.query_condition_charts {
  display: flex;
  align-items: center;
}

.query_condition {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
}

.active {
  background-color: #ffffff;
  color: #4073b6 !important;
}

.chartItem {
  cursor: pointer;
  padding: 10px 35px;
  color: #000;
  font-weight: 600;
}

.logTitle {
  font-size: 28px;
  font-weight: bold;
  color: #4073b6;
  display: inline-block;
  padding-right: 40px;
}

.chartContent {
  width: 100%;
  padding: 15px 0;
  background-color: #ffffff;
}

.table_title {
  color: #4073b6;
  font-weight: 700;
  font-size: 22px;
  margin-right: 22px;
}
</style>
